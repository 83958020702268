import * as _ from 'lodash';
import {UserClient} from "../../api/user-client";
import {PermissionClient} from "../../api/permission-client";
import {ModuleConfigClient} from "../../api/module-config-client";
import {ExpressionEvaluator} from "../../expression/expression-evaluator";
import {inject} from 'aurelia-framework';

@inject(UserClient, PermissionClient, ModuleConfigClient, ExpressionEvaluator)
export class TabProcessor
{
    _presets = {
        table:
            {
                content: '<sio-tab-table object.bind="object" tab.bind="tab"></sio-tab-table>',
                count: true
            }
        ,
        log:
            {
                content: '<sio-tab-log object.bind="object" tab.bind="tab"></sio-tab-log>',
            }
        ,
        overview:
            {
                content: '<sio-tab-overview object.bind="object" tab.bind="tab"></sio-tab-overview>',
            }
        ,
    };

    constructor(userClient, permissionClient, moduleConfigClient, expressionEvaluator)
    {
        this.userClient = userClient;
        this.permissionClient = permissionClient;
        this.moduleConfigClient = moduleConfigClient;
        this.expressionEvaluator = expressionEvaluator;
    }

    /**
     * Processes tab configuration:
     * -> loading config
     * -> preset resolving
     * -> if, permission and permission condition resolving
     * -> count config evaluation
     */
    async processTabs(config, object, context, overrideContext)
    {
        let tabs = [];
        let permissionTabs = [];

        //Load tabs
        if (_.isString(config)) {
            tabs = await this.moduleConfigClient.getTabs(config);
        } else {
            tabs = config;
        }

        //Process presets and filter tabs by permissions and conditions
        for (let i = 0; i < tabs.length; i++) {

            let tab = _.cloneDeep(tabs[i]);

            if (tab.preset) {
                let preset = this._presets[tab.preset.type];

                tab = Object.assign({}, preset, tab);

                if (tab.preset.properties) {
                    _.forIn(tab.preset.properties, (value, key) => {
                        tab.content = tab.content.replace('%'+ key +'%', value);
                    });

                    if (tab.count) {
                        tab.count = {
                            modelId: tab.preset.properties.modelId
                        };
                    }
                }
            }

            if (tab.if) {
                if (!this.expressionEvaluator.evaluate(tab.if, context, overrideContext)) {
                    continue;
                }
            }

            if (tab.permissions) {

                if (tab.permissions.permissions && !(await this.userClient.hasRole(tab.permissions.permissions))) {
                    continue;
                }

                if (tab.permissions.conditions && !(await this.permissionClient.matchesPermissionCondition(object, tab.permissions.conditions))) {
                    continue;
                }
            }

            permissionTabs.push(tab);
        }

        permissionTabs = _.sortBy(permissionTabs, ['weight']);

        return permissionTabs;
    }

}
